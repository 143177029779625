* {
  font-family: Roboto, sans-serif;
}
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
}
body {
  margin: 0px;
  font-size: 14px;
  background-color: #f9fafb;
}
html {
  overflow: hidden;
}
.normal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.lighter {
  font-weight: 300;
}
button {
  cursor: pointer;
}
.mainErrorWrapper {
  text-align: center;
  margin-top: 70px;
}
.mainErrorWrapper .btnError {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background: none;
  outline: none;
  border: 1px solid #d0d0d0;
}
[class*="rc-notification"] {
  border-radius: 5px;
  text-align: center;
}
[class*="rc-notification-notice"] {
  background: #464646 !important;
}
